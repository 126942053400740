// @ts-strict-ignore
import config from 'config';

/*
 * Types.
 */

declare global {
  interface Window {
    shouldForceProd?: boolean;
  }
}

interface ChatConfig {
  frontSettingsDomain: string;
  bugsnagKey: string;
  frontChatServerBaseDomain: string;
  chatIdentityInitializeEndpoint: string;
  chatIdentityTokenEndpoint: string;
  loadMessagesEndpoint: string;
  sendMessagesUrl: string;
  sendAttachmentEndpoint: string;
  ablyAuthEndpoint: string;
  chatSeenEndpoint: string;
  isKubeStaging: boolean;
  semanticSearchEndpoint: string;
}

/*
 * Constants.
 */

export const isTest = process.env.NODE_ENV === 'test';
export const isDevelopment = process.env.NODE_ENV !== 'production' && !isTest;

const prodFrontChatServerBaseDomain = 'chat-server.frontapp.com';
const prodFrontSettingsDomain = 'chat.frontapp.com';
const prodSendMessagesUrl = 'https://chat-webhook.frontapp.com/front-chat';

/**
 * Config overrides which allow the widget to connect directly to production. This object
 * should _only_ be used in development, as these parameters are provided at build time
 * in the real production environment.
 */
const productionConfigOverride = Object.freeze({
  isKubeStaging: false,
  frontChatServerBaseDomain: prodFrontChatServerBaseDomain,
  frontSettingsDomain: prodFrontSettingsDomain,
  sendMessagesUrl: prodSendMessagesUrl,
});

/*
 * Helpers.
 */

/**
 * We only allow forcing a production config for development.
 */
function checkShouldForceProd() {
  return isDevelopment && window.shouldForceProd;
}

/**
 * Allows overriding the chat widget with a production configuration
 * while working in development.
 */
function makeConfig(): ChatConfig {
  if (checkShouldForceProd()) {
    return {
      ...config,
      ...productionConfigOverride,
    };
  }

  return config;
}

/**
 * config should never be directly imported outside of this
 * module, instead use chatConfig.
 */
export const chatConfig = makeConfig();
