export const ACTION = 'ACTION';
export const ON_EVENT_HANDLER = 'ON_EVENT_HANDLER';
export const OFF_EVENT_HANDLER = 'OFF_EVENT_HANDLER';
export const GETTER = 'GETTER';
export const DEBUG = 'DEBUG';

export const EV_UNREAD_CHANGE = 'unreadChange';
export const EV_CONTACT_FORM_SUBMIT = 'contactFormSubmit';
export const EV_INBOUND_MESSAGE_RECEIVED = 'inboundMessageReceived';
export const EV_WINDOW_VISIBILITY_CHANGED = 'windowVisibilityChanged';
export const EVENT_LIST = [
  EV_UNREAD_CHANGE,
  EV_CONTACT_FORM_SUBMIT,
  EV_INBOUND_MESSAGE_RECEIVED,
  EV_WINDOW_VISIBILITY_CHANGED,
];

export const CMD_INIT = 'init';
const CMD_IDENTITY = 'identity';
const CMD_SHUTDOWN = 'shutdown';
const CMD_HIDE = 'hide';
const CMD_SHOW = 'show';
const CMD_TRIGGER_MESSAGE = 'triggerMessage';

const CMD_ON_UNREAD_CHANGE = 'onUnreadChange';
const CMD_OFF_UNREAD_CHANGE = 'offUnreadChange';
const CMD_ON_CONTACT_FORM_SUBMIT = 'onContactFormSubmit';
const CMD_ON_INBOUND_MESSAGE_RECEIVED = 'onInboundMessageReceived';
const CMD_ON_WINDOW_VISIBILITY_CHANGED = 'onWindowVisibilityChanged';

const CMD_VALUE_UNREAD_COUNT = 'unread_count';
const CMD_VALUE_USER_ID = 'user_id';
export const CMD_DEBUG = 'debug';
export const CMD_PREVIEW_SETTINGS = 'previewSettings';
export const CMD_PREVIEW_CHATBOT = 'previewChatbot';

const COMMAND_LIST = [
  {
    name: CMD_INIT,
    type: ACTION,
  },
  {
    name: CMD_IDENTITY,
    type: ACTION,
  },
  {
    name: CMD_SHUTDOWN,
    type: ACTION,
  },
  {
    name: CMD_HIDE,
    type: ACTION,
  },
  {
    name: CMD_SHOW,
    type: ACTION,
  },
  {
    name: CMD_TRIGGER_MESSAGE,
    type: ACTION,
  },
  {
    name: CMD_ON_UNREAD_CHANGE,
    type: ON_EVENT_HANDLER,
    event: EV_UNREAD_CHANGE,
  },
  {
    name: CMD_OFF_UNREAD_CHANGE,
    type: OFF_EVENT_HANDLER,
    event: EV_UNREAD_CHANGE,
  },
  {
    name: CMD_VALUE_UNREAD_COUNT,
    type: GETTER,
  },
  {
    name: CMD_VALUE_USER_ID,
    type: GETTER,
  },
  {
    name: CMD_DEBUG,
    type: DEBUG,
  },
  {
    name: CMD_ON_CONTACT_FORM_SUBMIT,
    type: ON_EVENT_HANDLER,
    event: EV_CONTACT_FORM_SUBMIT,
  },
  {
    name: CMD_ON_INBOUND_MESSAGE_RECEIVED,
    type: ON_EVENT_HANDLER,
    event: EV_INBOUND_MESSAGE_RECEIVED,
  },
  {
    name: CMD_ON_WINDOW_VISIBILITY_CHANGED,
    type: ON_EVENT_HANDLER,
    event: EV_WINDOW_VISIBILITY_CHANGED,
  },
  {
    name: CMD_PREVIEW_SETTINGS,
    type: ACTION,
  },
  {
    name: CMD_PREVIEW_CHATBOT,
    type: ACTION,
  },
];

export const commandListNames = COMMAND_LIST.map((command) => command.name);

export const runWrapped = (cmdName, params, run) => {
  const cmd = COMMAND_LIST.find((c) => c.name === cmdName);
  if (!cmd) {
    // eslint-disable-next-line
    console.error(`[FrontChat] Command not found: "${cmdName}"`);
    return undefined;
  }

  return run(cmd);
};
