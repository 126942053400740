import {mockBeforeLoadAPI, setupFrontChatMiddleware} from './middlewares/sdk';

/* eslint prefer-template: 0 */
/* global BUILD_HASH, CHAT_ASSETS_PATH, CHAT_CLIENT_VERSION */
// This is the chat snippet that every user will fetch
// eslint-disable-next-line
(function () {
  try {
    console.warn(`[FrontChat][Front] SDK ${CHAT_CLIENT_VERSION}`);

    // Enqueue all API calls
    mockBeforeLoadAPI();
    const d = document;

    const iframeEl = d.createElement('iframe');
    iframeEl.id = 'front-chat-iframe';
    iframeEl.title = 'Front Chat';
    iframeEl.style.position = 'fixed';
    iframeEl.style.border = 'none';
    iframeEl.style.zIndex = 99999999;
    iframeEl.style.colorScheme = 'normal';

    // set height and width to 0 so the iframe doesn't block a customer's page before app
    // load, and then is automatically styled to the correct height and width upon load
    iframeEl.style.height = 0;
    iframeEl.style.width = 0;

    setupFrontChatMiddleware(CHAT_CLIENT_VERSION, BUILD_HASH, CHAT_ASSETS_PATH, iframeEl);
  } catch (error) {
    console.error('[FrontChat][Front] Failed to initialize Front Chat SDK', error);
  }
})();
