import {isDevelopment} from '../helpers/envHelpers';
import {retryAsync} from '../helpers/promiseHelpers';
import type {Handler} from '../helpers/reactHelpers';
import {
  CMD_DEBUG,
  CMD_INIT,
  CMD_PREVIEW_CHATBOT,
  CMD_PREVIEW_SETTINGS,
  commandListNames,
  runWrapped, // @ts-expect-error: no-types
} from './api-wrapper';

/*
 * Constants.
 */

/** Key for the mock queue which is deleted once the app is ready. */
const CMD_QUEUE_NAME = '__frontCmdQueue';

const interceptCmdTypes = [CMD_INIT, CMD_PREVIEW_SETTINGS, CMD_PREVIEW_CHATBOT];

/*
 * Types.
 */

type CommandParams = Record<string, string | boolean | object>;
type FrontChatCommand = (cmdType: string, params?: CommandParams) => void;

declare global {
  interface Window {
    FrontChat?: FrontChatCommand;
    FrontChatApp?: FrontChatCommand;
    attachFrontChatResize?: Handler;
    attachMutationObserver?: (headElement: HTMLHeadElement, callback: MutationCallback) => void;
    cloneHeadIntoFrontChatIframe?: Handler;
    runEachCmdFromQueueAPI?: Handler;
    [CMD_QUEUE_NAME]?: Array<[string, CommandParams | undefined]>;
  }
}

/*
 * Helpers.
 */

/**
 * Retrieves the iframe element by id and returns undefined if nothing is found.
 *
 * This is duplicated from domHelpers since this is only needed from that file and importing that
 * entire file bloats the chat bundle.
 */
function getIframeElement(): HTMLIFrameElement | undefined {
  const iframeEl = document.getElementById('front-chat-iframe') as HTMLIFrameElement | null;
  return iframeEl ?? undefined;
}

/*
 * API command queue helpers. The SDK hits these queues prior to the app
 * being instantiated, at which point the commands will be passed along.
 */

export const mockBeforeLoadAPI = () => {
  window[CMD_QUEUE_NAME] = [];
  window.FrontChat = (cmdName, params) =>
    runWrapped(
      cmdName,
      params,
      () => window[CMD_QUEUE_NAME] && window[CMD_QUEUE_NAME].push([cmdName, params]),
    );
};

const runEachCmdFromQueueAPI = () => {
  if (Object.prototype.hasOwnProperty.call(window, CMD_QUEUE_NAME)) {
    window[CMD_QUEUE_NAME]?.forEach(
      ([cmdName, params]) => window.FrontChat && window.FrontChat(cmdName, params),
    );
    delete window[CMD_QUEUE_NAME];
  }
};

function attachRunEachCmdFromQueueAPI() {
  const iframeEl = getIframeElement();
  const iframeWin = iframeEl?.contentWindow;

  if (!iframeWin) {
    console.error('[FrontChat] Cannot find #front-chat-iframe window');
    return;
  }

  iframeWin.runEachCmdFromQueueAPI = runEachCmdFromQueueAPI;
}

/*
 * Middleware helpers.
 */

function attachFrontChatResize() {
  const iframeEl = getIframeElement();
  const iframeWin = iframeEl?.contentWindow;

  if (!iframeWin) {
    console.error('[FrontChat] Cannot find #front-chat-iframe window');
    return;
  }

  iframeWin.frontChatResize = ({placementLocation, bottomSpacing, sideSpacing, width, height}) => {
    iframeEl.style.width = width;
    iframeEl.style.height = height;

    iframeEl.style.bottom = bottomSpacing;

    if (placementLocation === 'bottom_left') {
      iframeEl.style.left = sideSpacing;
    } else {
      iframeEl.style.right = sideSpacing;
    }
  };
}

/**
 * For dev only, this is used to monitor changes to the top document head and replicate it into our iframe.
 */
function attachMutationObserver(headElement: HTMLHeadElement, callback: MutationCallback) {
  const observer = new MutationObserver(callback);

  const config = {
    attributes: true,
    childList: true,
    subtree: true,
  };

  observer.observe(headElement, config);
}

/**
 * Copies the contents of the head element's inner HTML into the front-chat-iframe. Used in development
 * to provide our styled components classes to the iframe during hot reload.
 */
export function cloneHeadIntoFrontChatIframe() {
  const iframeEl = document.getElementById('front-chat-iframe') as HTMLIFrameElement;
  const iframeDoc = iframeEl?.contentDocument;
  if (!iframeDoc) {
    return;
  }

  const headHtml = document.head.innerHTML;
  iframeDoc.head.innerHTML = headHtml;
}

if (isDevelopment) {
  window.attachFrontChatResize = attachFrontChatResize;
  window.attachMutationObserver = attachMutationObserver;
  window.cloneHeadIntoFrontChatIframe = cloneHeadIntoFrontChatIframe;
}

/**
 * Setup the window.FrontChat service used by our customers.
 */
export function setupFrontChatMiddleware(
  chatVersion: string,
  buildHash: string,
  chatAssetsPath: string,
  iframeEl?: HTMLIFrameElement,
) {
  let currentIframeEl: HTMLIFrameElement | undefined;

  window.FrontChat = (cmdType, params) => {
    if (cmdType === CMD_DEBUG && chatVersion) {
      console.warn(`[FrontChat][Front] SDK ${chatVersion}`);
    }

    if (!commandListNames.includes(cmdType)) {
      console.error(`[FrontChat] Command not found: "${cmdType}"`);
      return undefined;
    }

    if (!iframeEl) {
      console.error('[FrontChat] Cannot find #front-chat-iframe');
      return undefined;
    }

    // The INIT and PREVIEW_* commands are not directly piped into the iframe, as the iframe is
    // itself created during initialization so the command must be intercepted.
    if (interceptCmdTypes.includes(cmdType)) {
      currentIframeEl = iframeEl.cloneNode() as HTMLIFrameElement;

      const frontChatContainerDiv = document.createElement('div');
      frontChatContainerDiv.setAttribute('id', 'front-chat-container');

      const appScript = document.createElement('script');
      appScript.setAttribute('type', 'text/javascript');

      const bundle = `app.bundle.js?v=${buildHash}`;
      const iframeSrc = (chatAssetsPath ?? '') + bundle;
      appScript.setAttribute('src', iframeSrc);

      if (typeof params?.nonce === 'string') {
        appScript.setAttribute('nonce', params.nonce);
      }

      // The w3 spec indicates that an iframe will inherit its parent document's CSP policy by virtue of
      // defining its document via srcdoc: https://www.w3.org/TR/CSP2/#processing-model-iframe-srcdoc
      // eslint-disable-next-line no-param-reassign
      currentIframeEl.srcdoc = `${frontChatContainerDiv.outerHTML}${appScript.outerHTML}`;

      // eslint-disable-next-line no-param-reassign
      currentIframeEl.onload = () => {
        // Attach runEachCmdFromQueueAPI from the SDK scope to the iframe window, so once the React app is ready it can clear its own queue.
        attachRunEachCmdFromQueueAPI();

        // Ensure that frontChatResize is available inside the iframe so that the React app can communicate resize changes.
        attachFrontChatResize();

        // Once the iframe is loaded and the React app is running, pass the INIT command through.
        handleCommandMaybeRetry(currentIframeEl, cmdType, params);
      };

      // If an old iframe can be found on the document, remove it before appending the newly cloned iframe.
      const oldIframe = window.document.getElementById('front-chat-iframe');
      if (oldIframe) {
        oldIframe.remove();
      }

      // The INIT command will be passed through during the iframe's onload event.
      document.body.appendChild(currentIframeEl);
      return undefined;
    }

    const cmdIframeEl = isDevelopment ? getIframeElement() : currentIframeEl;
    return handleCommandMaybeRetry(cmdIframeEl, cmdType, params);
  };
}

/**
 * There may be some time required for the iframe and app to initialize, so an exponential backoff
 * is used when intercepting the INIT command.
 */
function handleCommandMaybeRetry(
  iframeEl: HTMLIFrameElement | undefined,
  cmdType: string,
  params?: CommandParams,
) {
  // Use the default retryAsync parameters for any intercepted commands.
  if (interceptCmdTypes.includes(cmdType)) {
    retryAsync(async () => handleCommand(iframeEl, cmdType, params)).catch(console.error);
    return undefined;
  }

  // For all other commands we can directly pass it through to the API.
  try {
    return handleCommand(iframeEl, cmdType, params);
  } catch (error) {
    console.error(error);
  }

  return undefined;
}

/** Attempt to pass an SDK command, through the iframe, to the internal app's API. */
function handleCommand(iframeEl: HTMLIFrameElement | undefined, cmdType: string, params?: CommandParams) {
  const iframeWin = iframeEl?.contentWindow;
  if (!iframeWin) {
    throw new Error('[FrontChat] Cannot find #front-chat-iframe');
  }

  if (!iframeWin.FrontChatApp) {
    throw new Error('[FrontChat] Have not finished setting up FrontChatApp');
  }

  return iframeWin.FrontChatApp(cmdType, params);
}
