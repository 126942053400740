import type {AsyncHandler} from './reactHelpers';

/*
 * Constants.
 */

const defaultMaxRetryAttempts = 10;
// 50 ms.
const defaultRetryDelayMultiplier = 50;

/*
 * Types.
 */

interface RetryAsyncConfig {
  maxRetryAttempts?: number;
  retryDelayMultiplier?: number;
}

/*
 * Helpers.
 */

export async function setTimeoutAsync(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * Retries a function with an exponential back off.
 */
export async function retryAsync(
  fn: AsyncHandler,
  config: RetryAsyncConfig = {},
  attempt = 0,
): Promise<void> {
  const maxRetryAttempts = config.maxRetryAttempts ?? defaultMaxRetryAttempts;
  const retryDelayMultiplier = config.retryDelayMultiplier ?? defaultRetryDelayMultiplier;

  try {
    return await fn();
  } catch (error) {
    const nextAttempt = attempt + 1;
    if (nextAttempt >= maxRetryAttempts) {
      throw error;
    }

    // In ms. The first retry will be instant, following retries exponentially increase.
    const retryTime = (2 ** attempt - 1) * retryDelayMultiplier;

    await setTimeoutAsync(retryTime);
    return retryAsync(fn, config, nextAttempt);
  }
}

export async function forEachAsync<T>(array: ReadonlyArray<T>, callback: (item: T) => Promise<void>) {
  for (const item of array) {
    // eslint-disable-next-line no-await-in-loop
    await callback(item);
  }
}
